import { createStore } from "vuex";
import VuexPersist from "vuex-persist";
const vuexLocal = new VuexPersist({
  storage: window.localStorage, // 可选存储方式
});

export default createStore({
  plugins: [vuexLocal.plugin],

  state: {
    webConfig: {},
    footer: [],
    details: {},
  },
  mutations: {
    SET_WEB_CONFIG: (state, data) => {
      state.webConfig = data;
    },
    SET_FOOTER: (state, data) => {
      state.footer = data;
    },
    SET_DETAILS: (state, data) => {
      state.details = data;
    },
  },
  getters: {},
  actions: {},
  modules: {},
});
