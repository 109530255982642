export function copyToClipboard(text) {
  return new Promise((resolve, reject) => {
    if (navigator.clipboard) {
      navigator.clipboard
        .writeText(text)
        .then(() => {
          resolve("复制成功");
        })
        .catch((err) => {
          reject("复制失败: " + err);
        });
    } else {
      var textArea = document.createElement("textarea");
      textArea.value = text;
      textArea.style.position = "fixed"; // Avoid scrolling to bottom
      textArea.style.top = "0";
      textArea.style.left = "0";
      textArea.style.width = "2em";
      textArea.style.height = "2em";
      textArea.style.padding = "0";
      textArea.style.border = "none";
      textArea.style.outline = "none";
      textArea.style.boxShadow = "none";
      textArea.style.background = "transparent";
      document.body.appendChild(textArea);
      textArea.focus();
      textArea.select();

      try {
        var successful = document.execCommand("copy");
        document.body.removeChild(textArea);
        if (successful) {
          resolve("复制成功");
        } else {
          reject("复制失败");
        }
      } catch (err) {
        document.body.removeChild(textArea);
        reject("复制失败: " + err);
      }
    }
  });
}
