import axios from "axios";
import { getUser, setUser } from "@/utils/user";
const request = axios.create({
  baseURL: process.env.VUE_APP_BASE_API,
  timeout: 20000
});
const handelError = error => () => {
  showNotify({
    type: "danger",
    message: "网络错误，请稍后刷新重试"
  });
  console.log("错误信息" + error);
};
request.interceptors.request.use(config => {
  if (!getUser()) {
    setUser();
  }
  config.headers["VerificationCode"] = getUser();
  return config;
}, handelError);
request.interceptors.response.use(response => {
  let {
    data,
    status,
    message
  } = response;
  if (status === 200) {
    return data;
  } else {
    return Promise.reject({
      status,
      message,
      data
    });
  }
}, handelError);
export default request;