import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue";
export default {
  __name: 'App',
  setup(__props) {
    // import NavBar from "@/components/NavBar.vue";

    return (_ctx, _cache) => {
      const _component_router_view = _resolveComponent("router-view");
      return _openBlock(), _createBlock(_component_router_view);
    };
  }
};