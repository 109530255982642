import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import { Lazyload } from "vant";
import { sendErrorLog } from "./api";

const app = createApp(App);

// 记录用户行为
app.config.globalProperties.$handelUserLog = (type, name, info, component) => {
  try {
    sendErrorLog({
      logType: type,
      logName: name,
      logComponents: component,
      logInfo: info,
    });
  } catch (error) {
    console.log("记录失败", error);
  }
};

app.config.errorHandler = (err, instance, info) => {
  // 处理错误
  sendErrorLog({
    logType: "error",
    logName: String(err),
    logComponents: instance.$route.name,
    logInfo: info,
  }).then(res => {});
};

app.use(store).use(router).use(Lazyload).mount("#app");
